.side-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      // padding: 15px 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: rgba(0, 0, 0, 0.7);

      &.active,
      &.fouse,
      &.hover {
        i {
          background-color: rgba(0, 0, 0, 0.9);
          border-radius: 6px;
        }
      }

      svg {
        width: 20px;
      }

      i {
        display: block;
        padding: 2px 12px;

        &:hover,
        &.active {
          background-color: rgba(0, 0, 0, 0.8);
          color: $primary;
          border-radius: 6px;
          text-align: center;
        }
      }

      span {
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        margin-top: 2px;
      }
    }
  }



  i {
    margin-right: 0px;
  }

  .sidebar-label {
    flex-grow: 1;
  }

  .submenu {
    display: none;
    background: #f0f0f0;
    padding: 10px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}

.toggle-input {
  display: none;
}

.hamburger {
  position: relative;
  width: 1em;
  height: 1em;
  cursor: pointer;
  font-size: 25px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: none;

  &.active {
    .line {
      &:nth-child(1) {
        top: 50%;
        transform: translateY(-50%) rotateZ(-135deg);
        transition: transform 0.3s ease, top 0.3s ease;
      }

      &:nth-child(2) {
        right: 50%;
        width: 0;
        transition: width 0.2s ease 0.1s, right 0.2s ease 0.1s;
      }

      &:nth-child(3) {
        top: 50%;
        width: 100%;
        transform: translateY(-50%) rotateZ(135deg);
        transition: width 0.3s ease, transform 0.3s ease, top 0.3s ease;
      }
    }
  }

  .line {
    position: absolute;
    right: 0;
    width: 100%;
    height: 2px;
    border-radius: 0.125em;
    background: $primary;
    transition: width 0.3s ease, top 0.3s ease, transform 0.3s ease;

    &:nth-child(1) {
      top: 0.125em;
    }

    &:nth-child(2) {
      top: 0.438em;
    }

    &:nth-child(3) {
      top: 0.75em;
      width: 0.625em;
    }
  }
}

@media screen and (max-width:767px) {
  #body {
    &.active {
      overflow: hidden;

    }
  }

  #overlaymenu {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.44);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    position: fixed;
    z-index: 2;
    display: none;

    &.active {
      display: block;
    }
  }

  .hamburger {
    display: block;
  }


  .left-side {
    transform: translateX(-100%);
    transition: all 0.5s ease;
    width: 70% !important;
    z-index: 4;
    position: fixed;

    &.active {
      transform: translateX(0);

    }
  }

  .side-bar {

    li {
      margin-bottom: 5px;

      a {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: rgba(0, 0, 0, 0.7);
        gap: 10px;
        padding: 12px 10px;
        border-radius: 5px;

        &.active,
        &.fouse,
        &.hover {
          background-color: $primary;
          color: #fff;
        }

        span {
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }
}